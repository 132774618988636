import React from 'react';
import cx from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import ChevronDown from '../../svg/chevronDown.svg';

export const EcologyButton = ({ linkText, target, isLarge = false }) => {
  return (
    <AnchorLink
      href={`#${target}`}
      className={cx(
        'bg-brand rounded-full font-semibold text-lg py-2 px-12 text-white transition-colors duration-150 hover:bg-white hover:text-ecologyText',
        {
          'md:text-2xl': isLarge,
        }
      )}
    >
      {linkText || 'Learn More'}
    </AnchorLink>
  );
};
export const EcologySubmitButton = ({ linkText, isLarge = false, ...props }) => {
  return (
    <button
      className={cx(
        'bg-brand rounded-full font-semibold text-lg py-2 px-12 text-white shadow-xl transition-colors duration-150 hover:bg-white hover:text-ecologyText',
        {
          'md:text-2xl': isLarge,
        }
      )}
      {...props}
    >
      {linkText || 'Learn More'}
    </button>
  );
};
