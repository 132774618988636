import React from 'react'
import { Field, ErrorMessage } from 'formik';

export const Step2 = ({ setStep, values }) => {
    const [errors, setErrors] = React.useState(null);

    function handleNext() {
        if (values.projectName && values.projectLocation && values.projectSize && values.projectSize > 0 && values.projectVision && values.projectStatus) {
            return setStep(3);
        }
        const errs = {}
        //step 2
        if (!values.projectName) {
            errs.projectName = 'Project Name is required';
        }
        if (!values.projectLocation) {
            errs.projectLocation = 'Location is required';
        }
        if (!values.projectSize || values.projectSize < '0') {
            errs.projectSize = 'Valid Size is required';
        }
        if (!values.projectVision) {
            errs.projectVision = 'Project Vision is required';
        }
        if (!values.projectStatus) {
            errs.projectStatus = 'Project Status is required';
        }
        return setErrors(errs);
    }

    return (
        <div className='flex flex-col gap-y-6 md:gap-y-10 absolute top-0 left-0 h-full w-full md:p-20 px-6 py-10 justify-center'>
            <div>
                <span className='text-ecologyOrange text-lg'>2/3</span>
                <h3 className='text-3xl md:text-4xl text-darkGrey mt-2 font-display'>Project Details</h3>
            </div>
            <div className='flex flex-col gap-y-3'>
                <div>
                    <Field
                        type="text"
                        placeholder="What is your project name?"
                        name="projectName"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.projectName && <span>Name is required</span>}
                    </div>
                </div>

                <div>
                    <Field
                        type="text"
                        placeholder="Where is your project located?"
                        name="projectLocation"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.projectLocation && <span>Location is required</span>}
                    </div>
                </div>

                <div>
                    <Field
                        type="number"
                        placeholder="What is your project size (Ha)?"
                        name="projectSize"
                        className="ecology-form-input w-full "
                        min="1"
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.projectSize && <span>Valid Size is required</span>}
                    </div>
                </div>

                <div>
                    <Field
                        type="text"
                        placeholder="What is your project vision?"
                        name="projectVision"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.projectVision && <span>Vision is required</span>}
                    </div>
                </div>

                <div>
                    <Field component="select" name="projectStatus" className='ecology-form-input'>
                        <option value='' hidden>What is your project's land status?</option>
                        <option value="private">Private</option>
                        <option value="iwi">Iwi</option>
                        <option value="council">Council</option>
                        <option value="doc">DOC</option>
                        <option value="not sure">I don't know</option>
                    </Field>
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.projectStatus && <span>Status is required</span>}
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleNext()} className='font-display w-max mx-auto text-lg py-3 bg-ecologyOrange px-12 rounded-full text-white cursor-pointer transition-all ease-in-out duration-300 hover:bg-ecologyFormBg border-2 border-ecologyOrange hover:text-ecologyOrange'>Next step</div>
                <div onClick={() => setStep(1)} className='underline text-ecologyOrange text-sm mt-5 cursor-pointer'>Go back</div>
            </div>
        </div>
    )
}
