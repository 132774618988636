const isSSR = typeof window === 'undefined';

export function track(eventName, payload = {}) {
  if (isSSR) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...payload,
  });
}
