import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import lottie from 'lottie-web';
import cx from 'classnames';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const GridItem = ({ icon, title, index, containerRef, showIndex, _key, centerText }) => {
  const target = useRef();
  const heading = useRef();

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: target.current,
      loop: false,
      autoplay: false,
      path: icon.asset.url,
      renderer: 'svg',
    });

    animation.addEventListener('DOMLoaded', function () {
      gsap.from([target.current, heading.current], {
        yPercent: 20,
        opacity: 0,
        delay: 0.3 * index + 0.8,
        stagger: 0.2,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top center',
          onEnter: () => {
            setTimeout(() => {
              animation.play();
            }, 300 * index + 800);
          },
        },
      });
      ScrollTrigger.refresh();
    });
  }, []);

  return (
    <div key={_key}>
      <div className="w-full" ref={target}></div>
      <h4
        className={cx(' font-bold text-md mt-4 md:text-xl', {
          'text-center': centerText,
          'text-left': !centerText,
        })}
        ref={heading}
      >
        {showIndex && (
          <span className="w-10 h-10 rounded-full border-2 flex items-center justify-center mb-4">
            {index + 1}
          </span>
        )}
        {title}
      </h4>
    </div>
  );
};

export const LottieIcons = ({
  title,
  subtitle,
  icons,
  showIndex = false,
  theme = 'light',
  _key,
  centerText = false,
}) => {
  const container = useRef();
  const subheading = useRef();
  const heading = useRef();

  useEffect(() => {
    gsap.from([subheading.current, heading.current], {
      yPercent: 30,
      opacity: 0,
      duration: 0.8,
      stagger: 0.2,
      ease: 'power4',
      scrollTrigger: {
        trigger: container.current,
        start: 'top center',
      },
    });
  }, []);

  return (
    <div
      className={cx('px-gutter w-full bg-ecologyGreen py-16 relative z-10 md:py-32', {
        'bg-ecologyGreen text-ecologyText': theme === 'light',
        'bg-ecologyNavy text-white': theme === 'dark',
      })}
      ref={container}
      key={_key}
    >
      <div className="text-left">
        <h4 className="font-bold text-brand text-2xl mb-5 md:text-3xl" ref={subheading}>
          {subtitle}
        </h4>
        <h2
          className="font-display text-3xl leading-snug md:text-5xl md:leading-snug max-w-5xl"
          ref={heading}
        >
          {title}
        </h2>
      </div>
      <div className="grid grid-cols-2 gap-10 max-w-7xl mx-auto pt-20 md:grid-cols-4 md:gap-x-24">
        {icons.map((item, index) => (
          <GridItem
            {...item}
            index={index}
            containerRef={container}
            showIndex={showIndex}
            centerText={centerText}
          />
        ))}
      </div>
    </div>
  );
};
