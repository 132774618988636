import React from 'react'

export const ThankYou = ({ setStep }) => {
    return (
        <div className='text-darkGrey absolute top-0 left-0 h-full w-full md:p-20 px-6 py-10 justify-center flex flex-col'>
            <span className='text-3xl md:text-4xl font-display block'>Thank you.</span>
            <p className='text-xl md:text-2xl mt-4'>We will give you a call within a week to help with your application. </p>
            <div onClick={() => setStep(0)} className='font-display mt-10 w-max mx-auto text-lg py-3 bg-ecologyOrange px-12 rounded-full text-white cursor-pointer transition-all ease-in-out duration-300 hover:bg-ecologyFormBg border-2 border-ecologyOrange hover:text-ecologyOrange'>New application</div>
        </div>
    )
}
