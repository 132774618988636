import React, { useLayoutEffect } from 'react';
import { graphql } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Hooks & Querys
import { QueryFragments } from '../GraphQl/queryFragments'; //eslint-disable-line

import Layout from '../components/layout';
import SEO from '../components/seo';

import { PageBanner } from '../components/ecologyPage/pageBanner';
import { VideoBlock } from '../components/ecologyPage/videoBlock';
import { LottieIcons } from '../components/ecologyPage/lottieIcons';
import { MapSection } from '../components/ecologyPage/mapSection';
import { LogoGrid } from '../components/ecologyPage/logoGrid';
import { EcologyForm } from '../components/ecologyPage/ecologyForm';
import RotateDevice from '../components/rotateDevice';

const LandingPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { metaDescription, metaTitle, metaKeywords, robots } = page.meta || {};
  const { header, video, lottieIcons, mapSection, sequenceSection, logoGrid, form } = page || {};

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);
  return (
    <Layout isEco>
      <RotateDevice />
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaRobots={robots}
      />

      <PageBanner {...header} />
      <VideoBlock video={video} />
      <LottieIcons {...lottieIcons} centerText={true} />
      <MapSection {...mapSection} />
      <LottieIcons {...sequenceSection} theme="dark" showIndex />
      <LogoGrid {...logoGrid} />
      <EcologyForm {...form} />
    </Layout>
  );
};

export default LandingPage;

export const pageQuery = graphql`
  query exoPageQuery($slug: String!) {
    page: sanityEcologyPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      header {
        backgroundImage {
          ...ImageWithPreview
        }
        heading
        linkText
        links {
          linkText
          link
        }
      }
      video {
        url
      }
      lottieIcons {
        _key
        subtitle
        title
        subtitle
        icons {
          _key
          title
          icon {
            asset {
              url
            }
          }
        }
      }
      mapSection {
        title
        subtitle
        beforeStat {
          title
          start
          end
          prefix
          suffix
        }
        afterStat {
          title
          start
          end
          prefix
          suffix
        }
      }
      sequenceSection {
        subtitle
        title
        subtitle
        icons {
          title
          icon {
            asset {
              url
            }
          }
        }
      }
      logoGrid {
        title
        subtitle
        logos {
          logo {
            ...ImageWithPreview
          }
          website
        }
      }
      form {
        title
        description
        confirmationText
        bgImage {
          ...ImageWithPreview
        }
      }
    }
  }
`;
