import React, { useState } from 'react';
import ReactPlayer from 'react-player/vimeo';

import Border from '../../svg/ecologyBorder.svg';
import PlayIcon from '../../svg/play.svg';
import CloseIcon from '../../svg/cross.svg';

import BlockStyles from '../portableTextBlocks/videoModule.module.css';

import PortalModal from '../modals/portalModal';

import { track } from '../../lib/gtm';

export const VideoBlock = ({ video }) => {
  const [isActive, setActive] = useState(false);
  const [vidPercent, setPercent] = useState(false);

  const trackProgress = ({ played }) => {
    if (played > 0.25 && played < 0.5 && vidPercent !== 1) {
      setPercent(1);
      track('Video 25%');
    }
    if (played > 0.5 && played < 0.75 && vidPercent !== 2) {
      setPercent(2);
      track('Video 50%');
    }
    if (played > 0.75 && played < 1 && vidPercent !== 3) {
      setPercent(3);
      track('Video 75%');
    }
  };

  return (
    <div className="relative z-10 grid grid-cols-14">
      {/* Video */}
      <div className="col-start-2 col-end-14 row-start-1 row-span-4 relative z-20 cursor-pointer md:row-span-6 md:row-start-1 lg:col-start-4 lg:col-end-12 shadow-2xl bg-black">
        <div
          className="top-0 left-0 absolute w-full h-full cursor-pointer flex items-center justify-center z-20 bg-black bg-opacity-30"
          onClick={() => {
            setActive(true);
            track('Video Click');
          }}
        >
          <span className="text-white flex items-center gap-x-4 transition-colors duration-100 hover:text-brand">
            <PlayIcon className="w-12 fill-current pt-2" />
            <span className="font-display mt-2 hidden md:block text-videoButton leading-none">
              Watch the video
            </span>
          </span>
        </div>
        <div className={`${BlockStyles.wrapper} w-full`}>
          <ReactPlayer url={video.url} playing playsinline muted loop width="100%" />
        </div>
      </div>
      {/* Border */}
      <div className="col-start-1 col-end-15 row-start-2 row-end-3 relative">
        <Border className="text-ecologyGreen fill-current" />
      </div>
      {/* End */}
      <div className="bg-ecologyGreen col-start-1 col-end-15 row-start-3 row-span-6 relative z-10 lg:pb-48" />
      {/* Modal */}
      <PortalModal isShowing={isActive}>
        <div className="w-full h-full flex items-center relative">
          <button
            className="absolute top-4 right-4"
            onClick={() => {
              setActive(false);
              track('Video Close');
            }}
          >
            <CloseIcon className="w-4 text-white fill-current" />
          </button>
          <div className={`${BlockStyles.wrapper} w-full max-w-7xl mx-auto`}>
            <ReactPlayer
              url={video.url}
              playing
              width="100%"
              controls
              onEnded={() => {
                setActive(false);
                track('Video 100%');
              }}
              onPause={() => {
                track('Video Pause');
              }}
              onProgress={trackProgress}
            />
          </div>
        </div>
      </PortalModal>
    </div>
  );
};
