import React from 'react'

export const Intro = ({ setStep }) => {
    return (
        <div className='text-white font-display md:p-20 px-6 py-10'>
            <span className='text-base md:text-lg font-sans'>Apply now</span>
            <h3 className='text-3xl md:text-4xl mt-3'>Excited to hear you’re keen to be in Cahoots with us!</h3>
            <div className='mt-6 font-sans text-sm md:text-base'>
                <p>
                    With 10+ years of controlling destructive pests around the planet, we know that reversing biodiversity decline is a challenge that requires both scale and dedication. Goodnature is only part of that puzzle. We have an effective tool (and staff with years of global trapping expertise) but we need it operating in networks across the planet.
                </p>
                <p className='mt-2'>
                    That's where you come in! A dedicated group who know your environment and have a desire to see your local species thrive. As a collective, we have the power to make a huge impact - the potential for change is inspiring!
                </p>
                <a href="https://goodnature.co.nz/pages/terms-and-conditions" target='_blank' className='mt-2 underline block'>
                    Terms & Conditions
                </a>
            </div>
            <div onClick={() => setStep(1)} className='mt-10 text-lg md:text-2xl py-4 bg-white px-12 rounded-full text-ecologyOrange cursor-pointer transition-all ease-in-out duration-300 hover:bg-ecologyOrange border-2 border-white hover:text-white w-max mx-auto'>Register now</div>
        </div>
    )
}
