import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useLocation } from '@reach/router';
import { useInView } from 'react-intersection-observer';
import Image from 'gatsby-plugin-sanity-image';
import cx from 'classnames';

import Border from '../../svg/ecologyBorderRev.svg';
import { track } from '../../lib/gtm';

//Form Content
import './formSteps/ecologyForm.css';
import { Intro } from './formSteps/intro'
import { Step1 } from './formSteps/step1'
import { Step2 } from './formSteps/step2'
import { Step3 } from './formSteps/step3'
import { ThankYou } from './formSteps/thankYou'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const FormContent = ({ step, setStep, values }) => {

  switch (step) {
    case 0:
      return <Intro setStep={setStep} />;
    case 1:
      return <Step1 setStep={setStep} values={values} />;
    case 2:
      return <Step2 setStep={setStep} values={values} />;
    case 3:
      return <Step3 setStep={setStep} values={values} />;
    case 4:
      return <ThankYou setStep={setStep} />;
    default:
      return <Step1 setStep={setStep} />;
  }
}

export const EcologyForm = ({ bgImage }) => {
  const { pathname } = useLocation();
  const [formTracked, setFormTracked] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  const [step, setStep] = useState(0);

  return (
    <>
      {/* Backgound */}
      <div
        className={cx('w-full h-full fixed bottom-0 left-0 bg-black z-0 ', {
          hidden: !inView,
        })}
      >
        <Image {...bgImage} className="absolute w-full h-full object-cover opacity-50" />
      </div>
      <div className="relative w-full grid grid-cols-14 pb-24" id="sign-up" ref={ref}>
        {/* Border */}
        <div className="col-start-1 col-end-15 row-start-1 relative z-10">
          <Border className="text-ecologyGreen fill-current" />
        </div>
        <div className="mx-auto max-w-4xl w-full relative z-10 col-start-2 col-end-14 row-start-2 row-span-3 rounded-2xl overflow-hidden lg:col-start-5 lg:col-end-11 text-center">
          {/* Form */}
          <Formik
            initialValues={{
              //step 1
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              //step 2
              projectName: '',
              projectLocation: '',
              projectSize: '',
              projectVision: '',
              projectStatus: '',
              //step 3
              needHelp: false,
              useOtherPestControl: false,
              trapsNeeded: '',
              provideSpokesperson: false,
              agreeToTerms: false,
            }}
            validate={(values) => {
              if (!formTracked) {
                setFormTracked(true);
                track('Form Started');
              }
              const errors = {};
              if (!values.agreeToTerms) {
                errors.agreeToTerms = 'You must agree to the terms and conditions';
              }
              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              console.log(values)
              fetch(pathname || '/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                  'form-name': `ecologyForm`,
                  formId: 'signup',
                  ...values,
                }),
              })
                .then(() => {
                  setStep(4);
                  track('cahoot-form-submit');
                })
                .catch((error) => alert(error));
              resetForm();
            }}
          >
            {({
              onChange,
              errors,
              status,
              values,
              /* and other goodies */
            }) => (
              <Form
                className={cx("w-full ecology-form relative flex items-center justify-center transition-all ease-in-out duration-300", {
                  'bg-ecologyOrange': step === 0,
                  'bg-ecologyFormBg': step !== 0,
                })}
                name="ecologyForm"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                subject="Ecology Form"
              >
                {/* Hidden Fields for netlify */}
                <input type="hidden" name="form-name" value="ecologyForm" />
                <p hidden>
                  <label>
                    <input name="bot-field" onChange={onChange} />
                  </label>
                </p>
                {/* <FormContent step={step} setStep={setStep} values={values} /> */}

                {/* Layer steps */}
                <div className={cx({
                  'opacity-0 pointer-events-none': step !== 0,
                })}><Intro setStep={setStep} /></div>
                <div className={cx({
                  'opacity-0 pointer-events-none': step !== 1,
                })}><Step1 setStep={setStep} values={values} /></div>
                <div className={cx({
                  'opacity-0 pointer-events-none': step !== 2,
                })}><Step2 setStep={setStep} values={values} /></div>
                <div className={cx({
                  'opacity-0 pointer-events-none': step !== 3,
                })}><Step3 setStep={setStep} values={values} /></div>
                <div className={cx({
                  'opacity-0 pointer-events-none': step !== 4,
                })}><ThankYou setStep={setStep} /></div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
