import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import Marquee from 'react-fast-marquee';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { track } from '../../lib/gtm';

export const LogoGrid = ({ title, subtitle, logos }) => {
  const breakpoints = useBreakpoint();
  return (
    <div className="w-full text-white relative z-20">
      <div className="text-ecologyText w-full bg-ecologyGreen md:px-gutter py-16 md:py-32 ">
        <div className="text-center">
          <h4 className="font-bold text-brand text-2xl mb-5 md:text-3xl">{subtitle}</h4>
          <h2 className="font-display text-3xl leading-snug md:text-5xl md:leading-snug max-w-5xl mx-auto">
            {title}
          </h2>
        </div>
        {!breakpoints.md ? (
          <div className="grid grid-cols-3 gap-4 mt-10 md:flex md:justify-center md:gap-x-16">
            {logos.map((item) => (
              <a href={item.website} target="_blank" onClick={() => track('Partner Logo Click')}>
                <Image {...item.logo} className="md:w-36 mx-auto" />
              </a>
            ))}
          </div>
        ) : (
          <Marquee gradient={false} className="mt-10">
            <div className="flex">
              {logos.map((item) => (
                <a
                  className="w-24"
                  href={item.website}
                  target="_blank"
                  onClick={() => track('Partner Logo Click')}
                >
                  <Image {...item.logo} />
                </a>
              ))}
            </div>
          </Marquee>
        )}
      </div>
    </div>
  );
};
