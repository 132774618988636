import React from 'react'
import { Field, ErrorMessage } from 'formik';

export const Step1 = ({ setStep, values }) => {
    const [errors, setErrors] = React.useState(null);

    function handleNext() {

        if (values.firstName
            && values.lastName
            && values.email
            && values.phone
            && !(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
            && !(!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(values.phone))) {
            return setStep(2);
        }

        const errs = {}
        //step 1
        if (!values.firstName) {
            errs.firstName = 'First Name is required';
        }
        if (!values.lastName) {
            errs.lastName = 'Last Name is required';
        }
        if (!values.email) {
            errs.email = 'Email address is Required';
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errs.email = 'Invalid email address';
        }
        if (!values.phone) {
            errs.phone = 'Phone number is required';
        }
        if (!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(values.phone)) {
            errs.phone = 'Invalid phone number';
        }
        return setErrors(errs);
    }

    return (
        <div className='flex flex-col gap-y-6 md:gap-y-10 absolute top-0 left-0 h-full w-full md:p-20 px-6 py-10 justify-center'>
            <div>
                <span className='text-ecologyOrange text-lg'>1/3</span>
                <h3 className='text-3xl md:text-4xl text-darkGrey mt-2 font-display'>Project Contact</h3>
            </div>
            <div className='flex flex-col gap-y-3'>
                <div>
                    <Field
                        type="text"
                        placeholder="First name*"
                        name="firstName"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.firstName && <span>First Name is required</span>}
                    </div>
                </div>

                <div>
                    <Field
                        type="text"
                        placeholder="Last name*"
                        name="lastName"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.lastName && <span>Last Name is required</span>}
                    </div>
                </div>

                <div>
                    <Field
                        type="email"
                        placeholder="Email address*"
                        name="email"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.email && <span>Valid Email Address is required</span>}
                    </div>
                </div>

                <div>
                    <Field
                        type="text"
                        placeholder="Phone number*"
                        name="phone"
                        className="ecology-form-input w-full "
                    />
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        {errors?.phone && <span>Valid Phone Number is required</span>}
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleNext()} className='font-display w-max mx-auto text-lg py-3 bg-ecologyOrange px-12 rounded-full text-white cursor-pointer transition-all ease-in-out duration-300 hover:bg-ecologyFormBg border-2 border-ecologyOrange hover:text-ecologyOrange'>Next step</div>
                <div onClick={() => setStep(0)} className='underline text-ecologyOrange text-sm mt-5 cursor-pointer'>Go back</div>
            </div>
        </div>
    )
}
