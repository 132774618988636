import React from 'react';

import RotateIcon from '../svg/rotateDevice.svg';

const RotateDevice = () => {
  return (
    <div className="hidden ld:flex w-full h-full fixed top-0 left-0  items-center justify-center bg-ecologyNavy z-3000 text-white pointer-events-none">
      <div>
        <RotateIcon className="w-16 fill-current text-brand mx-auto" />
        <h4 className="font-display text-2xl mt-6">Rotate Device</h4>
      </div>
    </div>
  );
};

export default RotateDevice;
