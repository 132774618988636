import React from 'react'
import { ErrorMessage, Field } from 'formik';
import cx from 'classnames';

const Toggle = ({ stateObject }) => {
    return (
        <div className='absolute top-0 left-0 w-full h-full bg-white z-10 pointer-events-none rounded-full flex items-center'>
            <span className={cx('absolute text-sm left-3 transition-all ease-in-out duration-300 text-ecologyOrange', {
                'opacity-0': !stateObject,
                'opacity-100': stateObject,
            })}>Yes</span>
            <div className={cx('absolute right-1 w-6 h-6 rounded-full transition-all ease-in-out duration-300', {
                'bg-ecologyOrange left-1/2 transform translate-x-1/2': stateObject,
                'bg-darkGrey left-1': !stateObject,
            })} />
            <span className={cx('absolute text-sm right-3 transition-all ease-in-out duration-300', {
                'opacity-0': stateObject,
                'opacity-100': !stateObject,
            })}>No</span>
        </div>
    )
}

export const Step3 = ({ setStep, values }) => {

    return (
        <div className='flex flex-col gap-y-6 md:gap-y-10 text-darkGrey absolute top-0 left-0 h-full w-full md:p-20 px-6 py-10 justify-center'>
            <div>
                <span className='text-ecologyOrange text-lg'>3/3</span>
                <h3 className='text-3xl md:text-4xl text-darkGrey mt-2 font-display'>Your Trapping Network</h3>
            </div>

            <div className='flex flex-col gap-y-6 md:gap-y-3 text-left'>
                <div className='flex flex-col md:flex-row gap-y-3 gap-x-16 items-center justify-between'>
                    <span className='text-sm md:text-base'>Do you need help understanding how many traps you will need for your project?</span>
                    <div className='relative'>
                        <Field type="checkbox" name="needHelp" className='opacity-0' />
                        <Toggle stateObject={values.needHelp} />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-y-3 gap-x-16 items-center justify-between'>
                    <span className='text-sm md:text-base'>Do you use another method of pest control?</span>
                    <div className='relative'>
                        <Field type="checkbox" name="useOtherPestControl" className='opacity-0' />
                        <Toggle stateObject={values.useOtherPestControl} />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-y-3 gap-x-16 items-center justify-between'>
                    <div className='flex flex-col'>
                        <span className='text-sm md:text-base'>
                            How many traps do you intend to purchase?
                        </span>
                        <span className='text-xs md:text-sm'>Based on your project land size, we recommend 2 traps per hectare. Minimum of 10 traps per order.</span>
                    </div>
                    <div className='relative'>
                        <Field type="number" required name="trapsNeeded" placeholder="10 Traps" min="10" className='ecology-form-input w-28 ' />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-y-3 gap-x-16 items-center justify-between'>
                    <span className='text-sm md:text-base'>Are you comfortable with us capturing your group/project in film/imagery and providing one or more spokespeople?</span>
                    <div className='relative'>
                        <Field type="checkbox" name="provideSpokesperson" className='opacity-0' />
                        <Toggle stateObject={values.provideSpokesperson} />
                    </div>
                </div>
                <div>
                    <div className='flex flex-col md:flex-row gap-y-3 gap-x-16 items-center justify-between'>
                        <span className='text-sm md:text-base'>Have you read Cahoot Terms and Conditions and understand that Cahoot is intended as a partnership between your group and Goodnature?</span>
                        <div className='relative'>
                            <Field type="checkbox" name="agreeToTerms" className='opacity-0' />
                            <Toggle stateObject={values.agreeToTerms} />
                        </div>
                    </div>
                    <div className="text-xs text-left mt-3 text-ecologyOrange">
                        <ErrorMessage name="agreeToTerms" />
                    </div>
                </div>
            </div>

            <div className='text-xs'>
                <p className='text-dark-grey'>
                    Your application will not guarantee the offer until we have contacted you and determined that your project meets the criteria to receive the offer.
                </p>
                <a href="https://goodnature.co.nz/pages/terms-and-conditions" target='_blank' className='mt-2 underline block text-ecologyOrange'>
                    Terms & Conditions
                </a>
            </div>

            <div>
                <button type='submit' className='font-display w-max mx-auto text-lg py-3 bg-ecologyOrange px-12 rounded-full text-white cursor-pointer transition-all ease-in-out duration-300 hover:bg-ecologyFormBg border-2 border-ecologyOrange hover:text-ecologyOrange'>Submit application</button>
                <div onClick={() => setStep(2)} className='underline text-ecologyOrange text-sm mt-5 cursor-pointer'>Go back</div>
            </div>
        </div>
    )
}
