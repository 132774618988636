import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import lottie from 'lottie-web';

import MapPin from '../../svg/mapPin.svg';
import TrapLine from '../../svg/trapLine.svg';

const StatsSection = ({ label, stat, isBrand = false, target }) => {
  const statsRef = useRef();

  useEffect(() => {
    if (isBrand) {
      gsap.from(statsRef.current, {
        textContent: 0,
        duration: 4,
        snap: { textContent: 1 },
        stagger: 1,
        scrollTrigger: {
          trigger: target.current,
          start: 'top top-=400',
          end: '+=800',
          scrub: 1,
          refreshPriority: 1,
        },
      });
    }
  }, []);

  return (
    <div
      className={cx({
        'text-brand': isBrand,
        'text-white': !isBrand,
      })}
    >
      <h4 className="font-display text-center text-xs md:text-lg">{label}</h4>
      <div className="flex justify-center gap-x-10">
        <div className="text-center">
          <h4 className="font-display text-4xl md:text-6xl">
            <span ref={statsRef}>{stat.end}</span>
          </h4>
          <h6 className="max-w-xs md:px-16">{stat.title}</h6>
        </div>
      </div>
    </div>
  );
};

export const MapSection = ({ title, subtitle, beforeStat, afterStat }) => {
  const target = useRef();
  const aniTarget = useRef();
  const statsTarget = useRef();
  const subheading = useRef();
  const heading = useRef();

  const breakpoints = useBreakpoint();

  useEffect(() => {
    let playhead = { frame: 0 };

    const animation = lottie.loadAnimation({
      container: aniTarget.current,
      loop: false,
      autoplay: false,
      path: '/mapDesktop.json',
      renderer: 'svg',
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });

    animation.addEventListener('DOMLoaded', function () {
      gsap.to(playhead, {
        frame: animation.totalFrames - 1,
        ease: 'none',
        onUpdate: () => animation.goToAndStop(playhead.frame, true),
        scrollTrigger: {
          trigger: target.current,
          pin: true,
          start: 'top top',
          end: '+=1000',
          scrub: 1,
        },
      });
      gsap.from(statsTarget.current, {
        yPercent: 30,
        opacity: 0,
        ease: 'none',
        scrollTrigger: {
          trigger: target.current,
          start: 'top center',
        },
      });
      gsap.from([subheading.current, heading.current], {
        yPercent: 30,
        opacity: 0,
        stagger: 0.2,
        scrollTrigger: {
          trigger: target.current,
          start: 'top center',
        },
      });
      ScrollTrigger.refresh();
    });
  }, []);

  return (
    <div
      className="w-full h-screen relative grid grid-cols-14 grid-rows-6 bg-ecologyGreen z-20"
      ref={target}
    >
      {/* Heading */}
      <div className="col-start-2 col-end-14 row-start-1 row-end-2 py-6 relative z-10 text-left md:text-center md:py-16 xl:col-start-8 xl:text-left">
        <h4 className="font-bold text-brand text-lg mb-2 md:text-3xl" ref={subheading}>
          {subtitle}
        </h4>
        <h2
          className="font-display text-xl text-ecologyText leading-snug max-w-5xl mx-auto md:text-3xl md:leading-snug  xl:ml-0 xl:text-5xl xl:leading-snug"
          ref={heading}
        >
          {title}
        </h2>
      </div>
      {/* Legend */}
      <div
        className="row-start-6 self-center col-start-2 col-end-14  bg-white relative z-10 rounded-lg p-3 lg:col-start-11 xl:col-start-12 xl:col-end-14 lg:row-start-5 lg:row-end-7"
        ref={statsTarget}
      >
        <div className="grid grid-cols-3 gap-y-4 text-xs font-display md:text-xl lg:grid-cols-1">
          <div className="flex items-center gap-x-3">
            <TrapLine className="text-brand fill-current w-4" /> Trap Lines
          </div>
          <div className="flex items-center gap-x-3">
            <MapPin className="text-ecologyNavy fill-current w-4" /> Purchased Traps
          </div>
          <div className="flex items-center gap-x-3">
            <MapPin className="text-brand fill-current w-4" /> Received Traps
          </div>
        </div>
      </div>
      {/* Stats */}
      <div className="col-start-1 col-end-15 row-start-5 row-end-6 relative z-10 self-center grid grid-cols-2 divide-x-2 divide-white divide-opacity-50 lg:col-end-7 lg:row-end-7">
        {/* Before */}
        <StatsSection label="Before" stat={beforeStat} target={target} />
        {/* After */}
        <StatsSection label="After" stat={afterStat} isBrand target={target} />
      </div>
      {/* Map Desk */}
      <div
        className="absolute w-full h-1/2 transform top-1/2 -translate-y-1/2 md:h-full md:top-0 md:translate-y-0 left-0 z-0 "
        ref={aniTarget}
      />
      {/* Border Bottom */}
      <span className="bg-ecologyNavy col-start-1 col-end-15 row-start-4 row-end-7" />
    </div>
  );
};
