import React, { useEffect, useRef } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { gsap } from 'gsap';

import OneLogo from '../../svg/onePlusOne.svg';
import GnLogo from '../../svg/logo.svg';

import { EcologyButton } from './ecologyButton';

import { track } from '../../lib/gtm';

export const PageBanner = ({ backgroundImage, heading, linkText, links }) => {
  const image = useRef();
  const headline = useRef();
  const btn = useRef();

  useEffect(() => {
    gsap.to(image.current, {
      yPercent: 20,
      ease: 'none',
      scrollTrigger: {
        trigger: image.current,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      },
    });
    gsap.from([headline.current, btn.current], {
      yPercent: 20,
      opacity: 0,
      stagger: 0.2,
      delay: 1,
    });
  }, []);
  return (
    <>
      {/* Background Image */}
      <div className="w-full absolute top-0 left-0 z-10 eco-bg" ref={image}>
        <span className="bg-black w-full h-full absolute top-0 left-0 opacity-25 z-10" />
        {/* Background Image */}
        {backgroundImage && backgroundImage.asset && (
          <Image {...backgroundImage} className="absolute w-full h-full object-cover" />
        )}
      </div>
      {/* Banner */}
      <div className="px-gutter relative z-10 text-white py-12 grid gap-y-10 lg:grid-cols-14 lg:px-0">
        <div className="row-start-1 md:flex md:items-center md:justify-between  lg:col-start-3 lg:col-end-13">
          {/* Goodnature Logo */}
          <a
            className="w-52 mx-auto lg:ml-0"
            href="https://goodnature.co.nz/"
            target="_blank"
            onClick={() => track('Logo Click')}
          >
            <GnLogo />
          </a>
          {/* Nav */}
          <nav className="gap-x-3 flex w-full mt-4 justify-center md:mt-0 md:justify-end lg:gap-x-10">
            {links.map(({ linkText, link }) => (
              <a
                href={link}
                target="_blank"
                className="font-display"
                onClick={() => track(`${linkText} Click`)}
              >
                {linkText}
              </a>
            ))}
          </nav>
        </div>
        <div className="row-start-2 grid gap-y-10 lg:col-start-3 lg:col-end-13 lg:grid-cols-2 lg:py-40 lg:gap-x-20 2xl:col-start-4 2xl:col-end-12">
          {/* Logo */}
          <div className="mt-3 mb-6 md:my-0">
            <OneLogo className="fill-current w-full md:mx-auto md:w-96 lg:w-full" />
          </div>
          {/* Text */}
          <div className="lg:pl-10">
            <h1
              className="font-display font-bold text-left text-lg mx-auto md:text-center lg:text-left lg:ml-0"
              ref={headline}
            >
              {heading}
            </h1>
            <div
              className="ml-auto flex justify-start mt-6 md:justify-center lg:justify-start"
              ref={btn}
              onClick={() => track('Apply Button Click')}
            >
              <EcologyButton linkText={linkText} target="sign-up" isLarge />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
